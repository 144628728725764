// External libs
import React, { Component } from 'react'
import Axios from 'axios';

// Stores
import { withConfigStore } from 'stores/ConfigStore'
import { withLocaleStore } from 'stores/LocaleStore';

// CSS
import './_.css'

class HomeCountersComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.processCounters = this.processCounters.bind(this)
    this.processCounter = this.processCounter.bind(this)
  }

  async getCounters(url, counter) {
    try {
      let response = await Axios.get(url)
      return {
        counter: counter,
        items: response.data.items
      };
    } catch {
      return null
    }
  }

  async processCounter(counter) {
    const { configStore } = this.props;
    let counterType = configStore.actions.getProperty('retrievo.ui.home.counters.' + counter + '.counterType', null);
    let counterSubtype = configStore.actions.getProperty('retrievo.ui.home.counters.' + counter + '.counterSubtype', null);
    let counterLimit = configStore.actions.getProperty('retrievo.ui.home.counters.' + counter + '.limit', 0);
    let url = configStore.actions.getApiUrl() + '/search/counters?page=1&per_page=' + counterLimit + '&sort_by=count.desc&filters.counterType.equals=' + counterType;

    if (counterSubtype) {
      url = url + '&facets.counterSubtype.selected=' + counterSubtype;
    }
    return await this.getCounters(url, counter);
  }

  processCounters() {
    const { configStore } = this.props;
    let allowedCounters = configStore.actions.getProperty('retrievo.ui.home.counters') || [];
    let countersPromises = allowedCounters.map(this.processCounter);

    Promise.all(countersPromises)
      .then((countersResults) => {
        let finalResult = countersResults.reduce((acumulador, counterResult) => {
          acumulador[counterResult.counter] = counterResult.items;
          return acumulador
        }, {})
        this.setState(finalResult)
      })
      .catch(() => {
        return null
      })
  }

  componentDidMount() {
    this.processCounters();
  }

  render() {
    return (
      <React.Fragment>
        {this.renderCounters()}
      </React.Fragment>
    );
  }

  renderCounters() {
    const { configStore, localeStore } = this.props;
    let allowedCounters = configStore.actions.getProperty('retrievo.ui.home.counters') || [];

    return allowedCounters.map((counter) => {
      let countersList = this.state[counter];
      let type = configStore.actions.getProperty("retrievo.ui.home.counters." + counter + ".type", "Simple").toLowerCase() || 'Simple';

      if (countersList != undefined && countersList.length > 0) {
        return (
          <div className={'counter-home ' + type + ' col-10 col-sm-9 col-md-4'} key={'home-counter-' + counter}>
            <h2>{localeStore.intl.formatMessage({ id: 'retrievo.ui.home.counters.' + counter + '.title' })}</h2>
            <ul>
              {this.renderCounter(countersList, type)}
            </ul>
          </div>
        )
      }
    })
  }


  renderCounter(countersList, type) {
    const { localeStore } = this.props;
    let largestCount = 0;
    let min = 1;
    let max = 10;
    let size;
    if (type === 'tagcloud') {
      largestCount = countersList.length ? countersList[0].count : 0;
      countersList = countersList.map((a) => ({ sort: Math.random(), value: a }))
        .sort((a, b) => a.sort - b.sort)
        .map((a) => a.value)
    }

    return countersList.map((counter) => {
      let label;
      size = (counter.count / largestCount) * (max - min) + min;
      let url;

      switch (counter.counterType.toLowerCase()) {
        case 'view':
          label = counter.metadata.title;
          url = '/#/records/' + counter.metadata.value;
          return this.rendeCounterByType(counter.id, type, label, size, counter.count, url)
        case 'search_term':
          label = counter.metadata.value;
          url = '/#/records?filters.0._all.equals=' + counter.metadata.value;
          return this.rendeCounterByType(counter.id, type, label, size, counter.count, url)
        default: {
          let defaultLabel = counter.metadata.title ? counter.metadata.title : counter.metadata.value;
          label = localeStore.intl.formatMessage({
            id: 'retrievo.ui.search.facets.records.' + counter.counterSubtype + '.' + counter.metadata.value + 
              '.label', defaultMessage: defaultLabel
          });
          url = '/#/records?facets.' + counter.counterSubtype + '.selected=' + counter.metadata.value
          return this.rendeCounterByType(counter.id, type, label, size, counter.count, url)
        }
      }
    })
  }

  rendeCounterByType(id, type, label, size, count, url) {
    switch (type) {
      case 'tagcloud':
        return (
          <li key={'home-counter-' + id}>
            <a href={url} title={label + ': ' + count} key={id}>
              <span className={'home-counter-label size-' + Math.round(size)}>{label}</span>
            </a>
          </li>
        );
      default:
        return (
          <li key={'home-counter-' + id}>
            <a href={url} title={label + ': ' + count} key={id}>
              <span className='home-counter-label'>{label}</span>
              <span className='home-counter-counter badge badge-light'>{count}</span>
            </a>
          </li>
        );
    }
  }
}

export default
  withConfigStore(
    withLocaleStore(
      HomeCountersComponent
    )
  );