import React, { Component } from 'react'
import { CSVLink } from "react-csv";
import Axios from 'axios';
import Cite from "citation-js";
import { File } from 'react-kawaii'
import cloneDeep from 'lodash/cloneDeep';

import { withConfigStore } from '../../../stores/ConfigStore'
import { withLocaleStore } from '../../../stores/LocaleStore'
import { withFavoriteStore } from '../../../stores/FavoriteStore'

import FooterComponent from 'components/layout/FooterComponent';
import NavbarComponent from 'components/layout/NavbarComponent';

import PaginationComponent from '../../../components/layout/PaginationComponent'
import DropdownComponent from '../../../components/global/elements/DropdownComponent'

class FavoritesComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filename: "all",
            perPage: this.initPerPage(),
            page: 1,
        };

        this.renderFavorites = this.renderFavorites.bind(this);
        this.downloadButton = this.downloadButton.bind(this);
        this.removeFavorite = this.removeFavorite.bind(this);
        this.goTo = this.goTo.bind(this);
        this.csvLink = React.createRef();
    }

    initPerPage() {
        const { configStore } = this.props;
        return  configStore.actions.getProperty('retrievo.ui.entity.perpage.favorites.default')
    }

    async downloadButton(event, type, favorite) {
        event.preventDefault();
        const { configStore, favoriteStore } = this.props;
        let favoritesList = favoriteStore.data.favorites;
        let favorites = []
        let data = []
        let url;
        let id;

        if (favorite) {
            this.setState({ filename: favorite.titles })
            favorites.push(favorite);
        } else {
            this.setState({ filename: "all" })
            favorites = favoritesList;
        }
        if (favorites.length > 0) {
            if (type == "csv") {
                for (let i = 0; i < favorites.length; i++) {
                    id = favorites[i].id
                    url = configStore.actions.getApiUrl() + '/search/records/' + id
                    let result = await Axios.get(url);
                    let dataFavorite = {
                        id: result.data.id,
                        dataSourceName: result.data.dataSourceName,
                        titles: result.data.metadataEnhanced.titles,
                        creators: result.data.metadataEnhanced.creators,
                        descriptions: result.data.metadataEnhanced.descriptions,
                        link: window.location.origin + '/#/records/' + result.data.id,
                        originalLink: result.data.metadataEnhanced.link
                    }
                    data.push(dataFavorite)
                }
                this.setState({ data }, () => {
                    setTimeout(() => {
                        this.csvLink.current.link.click();
                    });
                })
            } else {
                for (let i = 0; i < favorites.length; i++) {
                    id = favorites[i].id
                    url = configStore.actions.getApiUrl() + '/search/records/' + id
                    let result = await Axios.get(url);

                    let authors = result.data.metadataEnhanced.creators || [];
                    let dates = result.data.metadataEnhanced.dates || [];
                    let publishers = result.data.metadataEnhanced.publishers || [];

                    let author = []
                    let date;
                    let publisher;

                    if (authors.length >= 0) {
                        for (let i = 0; i < authors.length; i++) {
                            author.push({ name: authors[i] })
                        }
                    }
                    if (publishers) {
                        publisher = publishers[0]
                    }
                    if (dates) {
                        date = dates[0]
                    }

                    //BibJSON
                    let dataFavorite = {
                        title: result.data.metadataEnhanced.titles[0],
                        type: result.data.metadataEnhanced.types[0],
                        author: author,
                        date: { "published": date },
                        link: [{ url: result.data.metadataEnhanced.link }],
                        publisher: { "name": publisher }
                    }
                    data.push(dataFavorite)
                }

                let bibtex = new Cite(data, {
                    output: {
                        style: 'bibtex',
                        type: 'string'
                    }
                })

                var element = document.createElement('a');
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(await bibtex.get()));
                element.setAttribute('download', this.state.filename + ".bib");
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            }
        }
    }

    removeFavorite(event, favorite) {
        event.preventDefault();
        const { favoriteStore } = this.props;
        favoriteStore.actions.toggleFavorite(favorite);
    }

    cleanFavorites(event) {
        event.preventDefault();
        const { favoriteStore } = this.props;
        favoriteStore.actions.cleanFavorites();
    }

    goTo(resultId) {
        this.props.history.push({ pathname: '/records/' + resultId });
    }

    render() {
        return (
            <React.Fragment>
                <NavbarComponent />
                <section id='main-content' className='favorites-section'>
                    {this.renderHeader()}
                    {this.renderContent()}
                </section>
                <FooterComponent />
            </React.Fragment>
        );
    }

    renderHeader() {
        const { localeStore, favoriteStore } = this.props;
        let favorites = favoriteStore.data.favorites;
        return (
            <div className='public-header'>
                <div className='container-fluid public-container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="row">
                                <div className="col-12 favoritesHeader">
                                    <div className="col-12 col-md-6">
                                        <h1 className="container-title">{localeStore.intl.formatMessage({ id: 'retrievo.ui.favorites.title' })}</h1>
                                        <p className="container-description">{localeStore.intl.formatMessage({ id: 'retrievo.ui.favorites.description' })}</p>
                                    </div>
                                    {favorites.length > 0 ?
                                        <div className="col-12 col-md-6">
                                            <ul className="favoritesActions">
                                                <li>
                                                    <button className="btn btn-primary" href='#' onClick={(e) => this.downloadButton(e, "csv")}>
                                                        <span className="fas fa-file-csv" />
                                                        <span>{localeStore.intl.formatMessage({ id: 'retrievo.ui.records.exportAll-csv' })}</span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="btn btn-primary" href='#' onClick={(e) => this.downloadButton(e, "bibtex")}>
                                                        <span className="fas fa-file-export" />
                                                        <span>{localeStore.intl.formatMessage({ id: 'retrievo.ui.records.exportAll-bibtex' })}</span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="btn btn-primary" href='#' onClick={(e) => this.cleanFavorites(e)} >
                                                        <span className="fas fa-trash" />
                                                        <span>{localeStore.intl.formatMessage({ id: 'retrievo.ui.records.cleanFavorites' })}</span>
                                                    </button>
                                                </li>
                                                <li className="csvLink">
                                                    <CSVLink
                                                        data={this.state.data}
                                                        filename={this.state.filename + ".csv"}
                                                        target="_blank"
                                                        className="hidden"
                                                        ref={this.csvLink} />
                                                </li>
                                            </ul>
                                        </div> 
                                    : null }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderContent() {
        const { favoriteStore, localeStore } = this.props;
        let favorites = favoriteStore.data.favorites;
        return (
            <div className='container-fluid public-container favorites'>
                <div className='row'>
                    <div className='col-12'>
                        <div className="favorite-results">
                            {favorites.length > 0 ?
                                <ul className="favorite-results-list">
                                    {this.renderFavorites()}
                                </ul>
                                :
                                <div className='row justify-content-left no-results'>
                                    <div className='col-auto no-results-image'>
                                        <File
                                            size={72}
                                            color='#8bd1b8'
                                            mood='ko'
                                        />
                                    </div>
                                    <div className='col-auto no-results-text'>
                                        <h2>{localeStore.intl.formatMessage({ id: 'retrievo.ui.favorites.no-results.title' })}</h2>
                                        <p>{localeStore.intl.formatMessage({ id: 'retrievo.ui.favorites.no-results.description' })}</p>
                                    </div>
                                </div>
                            }
                        </div>
                        {favorites.length > 0 ? this.renderControls() : null}
                    </div>
                </div>
            </div>
        );
    }

    renderFavorites() {
        const { favoriteStore, localeStore } = this.props;
        let favorites = cloneDeep(favoriteStore.data.favorites);
        let i = (this.state.page-1) * this.state.perPage;
        let f = (this.state.perPage * this.state.page);

        if(f > favorites.length){
            f = favorites.length
        }

        let pagedFavorites = favorites.slice(i, f)
        return pagedFavorites.map((favorite, i) => {
            return (
                <li key={'favorite-' + i} className="container-fluid favorite-result">
                    <div className="favorite-result-link">
                        <div className="row">
                            <div className="col-12 col-sm order-3 order-sm-1 favorite-result-fields" onClick={() => { this.goTo(favorite.id) }}>
                                <a href={"/#/records/" + favorite.id} className="favorite-result-title" target='_blank' rel='noopener noreferrer'>
                                    <h2>{favorite.titles}</h2>
                                </a>
                                <p className="favorite-result-description">
                                    {favorite.descriptions}
                                </p>
                            </div>
                            <div className="col-auto order-1 order-sm-3 favorite-result-actions">
                                <ul>
                                    <li>
                                        <a href='#' onClick={(e) => this.downloadButton(e, "csv", favorite)}>
                                            <span className="fas fa-file-csv" />
                                            <span className="favorite-action">{localeStore.intl.formatMessage({ id: 'retrievo.ui.records.export-csv' })}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#' onClick={(e) => this.downloadButton(e, "bibtex", favorite)}>
                                            <span className="fas fa-file-export" />
                                            <span className="favorite-action">{localeStore.intl.formatMessage({ id: 'retrievo.ui.records.export-bibtex' })}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#' onClick={(e) => this.removeFavorite(e, favorite)}>
                                            <span className="fas fa-trash" />
                                            <span className="favorite-action">{localeStore.intl.formatMessage({ id: 'retrievo.ui.records.remove-favorite' })}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
            )
        });
    }

    renderControls() {
        const { localeStore, favoriteStore, configStore } = this.props;
        let favorites = favoriteStore.data.favorites;
        let options = JSON.parse("[" + configStore.actions.getProperty('retrievo.ui.entity.perpage.favorites.options') + "]");
        return (
          <div className='favorite-results-controls row'>
            <div className='favorite-pagination col-12 col-sm-6'>
              <PaginationComponent
                currentPage={this.state.page}
                perPage={this.state.perPage}
                total={favorites.length}
                onChange={(page) => {
                    this.setState({page})
                }}/>
            </div>
            <div className='favorite-per-page-results col-12 col-sm-6'>
              <label htmlFor={'per-page'}>
                {localeStore.intl.formatMessage({ 'id' : 'retrievo.ui.search.perpage'})}
              </label>
              <DropdownComponent
                selected={this.state.perPage}
                options={options}
                onChange={(perPage) => {
                    this.setState({perPage, page: 1})
                }}/>
            </div>
          </div>
        );
      }
}

export default withFavoriteStore(withLocaleStore((
    withConfigStore(FavoritesComponent)))
);
