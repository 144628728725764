import React from 'react'

// Stores
import {withLocaleStore} from 'stores/LocaleStore'
import {withConfigStore} from "stores/ConfigStore";

class FooterComponent extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    let mode = this.props.mode || 'simple';

    if(mode === 'full') {
      return (
        <footer id='footer' className='footer'>
          { this.renderImplFooter() }
          { this.renderMainFooter() }
        </footer>
      )
    } else {
      return (
        <footer id='footer' className='footer'>
          { this.renderMainFooter() }
        </footer>
      )
    }
  }

  renderImplFooter() {
    const { configStore, localeStore } = this.props;
    let footer = configStore.actions.getProperty('retrievo.ui.footer.' + localeStore.actions.getLocaleWithUnderScore()) ||
      configStore.actions.getProperty('retrievo.ui.footer.' + localeStore.actions.getLocaleWithoutRegionCode());

    return <div className='impl-footer' dangerouslySetInnerHTML={{__html: footer}} />;
  }

  renderMainFooter() {
    const { configStore, localeStore } = this.props;
    let footer = configStore.actions.getProperty('retrievo.ui.footer_common.' + localeStore.actions.getLocaleWithUnderScore()) ||
      configStore.actions.getProperty('retrievo.ui.footer_common.' + localeStore.actions.getLocaleWithoutRegionCode());
    footer = footer.replace('{currentYear}', new Date().getFullYear());
    return <div className='main-footer' dangerouslySetInnerHTML={{__html: footer}} />;
  }
}


export default withConfigStore(withLocaleStore(FooterComponent));
