// External
import React, { Component } from 'react'
import ReactGA from 'react-ga'
import { HashRouter as Router, Switch } from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location'
import { ToastContainer } from 'react-toastify'

// Containers
import PublicRoute from 'containers/public/PublicComponent'
import PrivateRoute from 'containers/admin/AdminComponent'

import AboutComponent from 'containers/admin/info/AboutComponent'
import ConfigurationsComponent from 'containers/admin/configurations/ConfigurationsComponent'
import CookiesComponent from 'containers/public/cookies/CookiesComponent'
import DashboardComponent from 'containers/admin/dashboard/DashboardComponent'
import DataConnectorComponent from 'containers/admin/data_sources/data_connectors/DataConnectorComponent'
import DataSourceComponent from 'containers/admin/data_sources/DataSourceComponent'
import DataSourcesComponent from 'containers/admin/data_sources/DataSourcesComponent'
import DataSourcesPublicComponent from 'containers/public/data_sources/DataSourcesComponent'
import ErrorComponent from 'containers/public/error/ErrorComponent'
import AboutPublicComponent from 'containers/public/about/AboutComponent'
import HomeComponent from 'containers/public/home/HomeComponent'
import AuthComponent from 'containers/public/auth/AuthComponent'
import LicenseComponent from 'containers/admin/info/LicenseComponent'
import RecordComponent from 'containers/public/record/RecordComponent'
import RecordsComponent from 'containers/public/record/RecordsComponent'
import ReportEntryComponent from 'containers/admin/tasks/ReportEntryComponent'
import TaskComponent from 'containers/admin/tasks/TaskComponent'
import TasksComponent from 'containers/admin/tasks/TasksComponent'
import FavoritesComponent from 'containers/public/favorites/FavoritesComponent'
// Global Components
import PageSpinnerComponent from 'components/global/misc/PageSpinnerComponent'

// Stores
import { LocaleStore } from 'stores/LocaleStore'
import { withUserStore } from 'stores/UserStore'
import { withConfigStore } from 'stores/ConfigStore'

class RouterComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { configStore } = this.props;
    this.initializeGoogleAnalyticsTracker();

    if (!configStore.data.loaded) {
      return <PageSpinnerComponent/>
    } else {
      return (
        <LocaleStore>
          <React.Fragment>
            { this.renderRouter() }
            { this.renderNotifications() }
          </React.Fragment>
        </LocaleStore>
      );
    }
  }


  initializeGoogleAnalyticsTracker() {
    const { configStore } = this.props;
    if (configStore.data.loaded) {
      // eslint-disable-next-line import/no-named-as-default-member
      ReactGA.initialize(configStore.actions.getProperty('retrievo.ui.ga', 'UA-0000000-0'));
    }
  }

  renderNotifications() {
    return <ToastContainer autoClose={8000} className='notification-container'/>;
  }

  renderRouter() {
    const { configStore } = this.props;
    let favoritesActive = configStore.actions.getProperty('retrievo.ui.entity.favoritesActive') || false;
    return (
      <Router>
        <LastLocationProvider>
          <Switch>
            {/* Public Pages */}
            <PublicRoute exact path={'/'} component={HomeComponent} {...this.props}/>
            <PublicRoute path={'/about'} component={AboutPublicComponent} {...this.props}/>
            <PublicRoute path={'/records/:recordId'} component={RecordComponent} {...this.props}/>
            <PublicRoute path={'/records'} component={RecordsComponent} {...this.props}/>
            <PublicRoute path={'/data_sources'} component={DataSourcesPublicComponent} {...this.props}/>
            <PublicRoute path={'/auth/:operation'} component={AuthComponent} {...this.props}/>
            <PublicRoute path={'/cookies'} component={CookiesComponent} {...this.props}/>
            {favoritesActive ? <PublicRoute path={'/favorites'} component={FavoritesComponent} {...this.props}/> : null}

            {/* Admin Pages */}
            <PrivateRoute path={'/admin/data_sources/:dataSourceId/data_connectors/:dataConnectorId'} component={DataConnectorComponent} {...this.props}/>
            <PrivateRoute path={'/admin/data_sources/:dataSourceId'} component={DataSourceComponent} {...this.props}/>
            <PrivateRoute path={'/admin/data_sources'} component={DataSourcesComponent} {...this.props}/>
            <PrivateRoute path={'/admin/tasks/:taskId/report_entries/:reportEntryId'} component={ReportEntryComponent} {...this.props}/>
            <PrivateRoute path={'/admin/tasks/:taskId'} component={TaskComponent} {...this.props}/>
            <PrivateRoute path={'/admin/tasks'} component={TasksComponent} {...this.props}/>
            <PrivateRoute path={'/admin/configurations/:type'} component={ConfigurationsComponent} {...this.props}/>
            <PrivateRoute path={'/admin/info/about'} component={AboutComponent} {...this.props}/>
            <PrivateRoute path={'/admin/info/license'} component={LicenseComponent} {...this.props}/>
            <PrivateRoute path={'/admin/dashboard/:section'} component={DashboardComponent} {...this.props}/>

            {/* Common Pages */}
            <PublicRoute component={ErrorComponent} {...this.props}/>
          </Switch>
        </LastLocationProvider>
      </Router>
    );
  }
}

export default withConfigStore(withUserStore(RouterComponent));
