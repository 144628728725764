// External libs
import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import Cookies from 'js-cookie/src/js.cookie.js'

// react-intl related
import { IntlProvider, injectIntl, addLocaleData } from 'react-intl'

// moment.js related
import 'moment/locale/pt';
import 'moment/locale/es';
import {withConfigStore} from "stores/ConfigStore";
import en from 'react-intl/locale-data/en.js';
import pt from 'react-intl/locale-data/pt.js';

// IE polyfills
/* eslint-disable no-unused-vars */
import enIntl from 'intl/locale-data/jsonp/en.js';
/* eslint-disable no-unused-vars */
import ptIntl from 'intl/locale-data/jsonp/pt.js';


// React context/provider/consumer related variables
const LocaleContext = React.createContext();
const LocaleStoreProvider = LocaleContext.Provider;
const LocaleStoreConsumer = LocaleContext.Consumer;

// Define user locale based on navigator language
const navigatorLanguage = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage || navigator.Systemlanguage || 'en';

// Define user locale based on previous visit
const cookieLanguage = Cookies.get('locale');

addLocaleData([...en, ...pt]);

const initialState = {
  lang: undefined,
  langWithoutRegionCode: undefined,
  messages: undefined
};

class LocaleStoreComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: this.getInitialState(),
      actions: {
        setLocale: this.setLocale.bind(this),
        getLocale: this.getLocale.bind(this),
        getLocaleWithDash: this.getLocaleWithDash.bind(this),
        getLocaleWithUnderScore: this.getLocaleWithUnderScore.bind(this),
        getLocaleWithoutRegionCode: this.getLocaleWithoutRegionCode.bind(this)
      }
    };
  }

  getState() {
    return cloneDeep(this.state);
  }

  getInitialState() {
    const {configStore} = this.props;
    let state = cloneDeep(initialState);
    let locale = this.getLocaleWithoutRegionCode(cookieLanguage || navigatorLanguage)
    const default_config_language = configStore.actions.getProperty('retrievo.ui.default_language');
    addLocaleData(require(`react-intl/locale-data/${locale}`));

    state['lang'] = cookieLanguage || default_config_language || navigatorLanguage;
    state['langWithoutRegionCode'] = this.getLocaleWithoutRegionCode(state['lang']);
    state['messages'] = configStore.data.i18n[state['lang'].replace('-', '_')] || configStore.data.i18n[state['langWithoutRegionCode']];
    
    // Fallback to english messages if no messages are loaded
    if(!state['messages'] || Object.keys(state['messages']).length < 1 ){
      state['lang'] = 'en';
      state['langWithoutRegionCode'] = 'en';
      state['messages'] = configStore.data.i18n['en'];
      
    }

    return state;
  }

  dispatch(action, args, callback) {
    let state = this.stateManager(this.state, action, args);
    this.setState(state, callback);
  }

  stateManager(state, action, args) {
    switch (action) {
      case 'SET_LOCALE':
        return {
          ...state,
          data: {
            lang: args.lang,
            langWithoutRegionCode: args.langWithoutRegionCode,
            messages: args.messages
          }
        };
      default:
        return state
    }
  }

  getLocale() {
    return this.getState().data.lang;
  }

  getLocaleWithDash() {
    return this.getState().data.lang;
  }

  getLocaleWithUnderScore() {
    return this.getState().data.lang.replace('-', '_');
  }

  getLocaleWithoutRegionCode(locale) {
    return locale === undefined ?
      this.getState().data.lang.toLowerCase().split(/[_-]+/)[0] :
      locale.toLocaleString().split(/[_-]+/)[0];
  }

  setLocale(locale) {
    const { configStore } = this.props;
    // Update current state with chosen locale
    let currentState = this.getState();
    let langWithoutRegionCode = this.getLocaleWithoutRegionCode(locale);
    addLocaleData(require(`react-intl/locale-data/${langWithoutRegionCode}`));

    currentState['lang'] = locale;
    currentState['langWithoutRegionCode'] = langWithoutRegionCode;
    currentState['messages'] = configStore.data.i18n[locale.replace('-', '_')] || configStore.data.i18n[langWithoutRegionCode];

     // Fallback to english messages if no messages are loaded
     if(!currentState['messages'] || Object.keys(currentState['messages']).length < 1 ){
      currentState['lang'] = 'en';
      currentState['langWithoutRegionCode'] = 'en';
      currentState['messages'] = configStore.data.i18n['en'];
      
    }

    // Set locale in cookie
    Cookies.set('locale', locale);

    // Update state
    this.dispatch('SET_LOCALE', currentState);
  }

  render() {
    window.document.documentElement.lang = this.state.data.lang;
    return (
      <LocaleStoreProvider value={this.state}>
        <IntlProvider
          onError={(message) => {
            // INFO 20190911 toliveira: custom callback to prevent error/warning of missing i18n messages
            // enable console.error if u wanna see missing i18n messages
            // console.error(message);
            return message;
          }}
          key={this.state.data.lang}
          locale={this.state.data.langWithoutRegionCode}
          messages={this.state.data.messages}>
          {this.props.children}
        </IntlProvider>
      </LocaleStoreProvider>
    );
  }

}

const LocaleStore = withConfigStore(LocaleStoreComponent);

// Providing context as higher-order-component
// https://reactjs.org/docs/context.html#consuming-context-with-a-hoc
function withLocaleStore(Component) {
  const WrappedComponent = function localeStore(props) {
    return(
      <LocaleStoreConsumer>
        { (localeStore) => {
          localeStore['intl'] = props.intl;
          return <Component {...props} localeStore={localeStore} />
        } }
      </LocaleStoreConsumer>
    )
  };

  return injectIntl(WrappedComponent);
}

export { LocaleStore, withLocaleStore }
