// External Libs
import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// Stores
import { withConfigStore } from 'stores/ConfigStore';

const FavoriteContext = React.createContext();
const FavoriteStoreProvider = FavoriteContext.Provider;
const FavoriteStoreConsumer = FavoriteContext.Consumer;

class FavoriteStoreComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                favorites: this.initFavorites(),
                showModal: false
            },
            actions: {
                toggleFavorite: this.toggleFavorite.bind(this),
                toggleModal: this.toggleModal.bind(this),
                cleanFavorites: this.cleanFavorites.bind(this)
            }
        }
    }

    render() {
        return (
            <FavoriteStoreProvider value={this.state}>
                {this.props.children}
            </FavoriteStoreProvider>
        );
    }

    getState() {
        return cloneDeep(this.state);
    }

    initFavorites() {
        let storedFavorites = localStorage.getItem('favorites');
        try {
            return JSON.parse(storedFavorites) || [];
        } catch (error) {
            return [];
        }
    }

    toggleFavorite(result) {
        const { configStore } = this.props;
        let maximumFavorites = configStore.actions.getProperty('retrievo.ui.entity.maximumFavorites', 10);
        let favorites = this.getState().data.favorites;
        const index = favorites.findIndex(favorite => favorite.id === result.id);

        if (index >= 0) {
            favorites.splice(index, 1);
        } else {
            if(favorites.length < maximumFavorites){
                favorites.push(result)
            }
        }

        localStorage.setItem('favorites', JSON.stringify(favorites))
        this.setState({ data: {...this.state.data, favorites: favorites } })
    }

    cleanFavorites() {
        localStorage.removeItem('favorites');
        this.setState({ data: {...this.state.data, favorites: [] } })
    }

    toggleModal() {
        if(this.state.data.showModal){
            this.setState({ data: {...this.state.data, showModal: false }});    
        }else{
            this.setState({ data: {...this.state.data, showModal: true }});
        }
    }
}

// Providing context as higher-order-component
// https://reactjs.org/docs/context.html#consuming-context-with-a-hoc
function withFavoriteStore(Component) {
    return function favoriteStore(props) {
        return (
            <FavoriteStoreConsumer>
                { (favoriteStore) => <Component {...props} favoriteStore={favoriteStore} />}
            </FavoriteStoreConsumer>
        )
    }
}

const FavoriteStore = withConfigStore(FavoriteStoreComponent);

export { FavoriteStore, withFavoriteStore, FavoriteContext }
