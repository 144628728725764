import React from 'react';
import Axios from 'axios';

const ConfigContext = React.createContext();
const ConfigStoreProvider = ConfigContext.Provider;
const ConfigStoreConsumer = ConfigContext.Consumer;

class ConfigStore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        configs: {},
        i18n: {},
        alli18n: [],
        loaded: undefined,
        loadedAdmin: undefined,
        lastModified: undefined
      },
      actions: {
        getProperty: this.getProperty.bind(this),
        getBaseUrl: this.getBaseUrl.bind(this),
        getApiUrl: this.getApiUrl.bind(this),
        loadPublicConfigurations: this.loadPublicConfigurations.bind(this),
        loadAdminConfigurations: this.loadAdminConfigurations.bind(this)
      }
    };
  }

  componentDidMount() {
    this.loadPublicConfigurations();
  }

  loadPublicConfigurations() {
    let url = this.getBaseUrl() + '/ext/cc-rest-ui/api/v1/properties_fields/ui-public?acceptFormat=json';
    Axios.get(url)
      .then((result) => {
        let res = this.filterConfigurations(result.data.propertiesWithFields);
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            configs: {
              ...this.state.data.configs,
              ...res.configs
            },
            i18n: {
              ...this.state.data.i18n,
              ...res.i18n
            },
            alli18n: [
              ...this.state.data.alli18n,
              ...res.alli18n
            ],
            loaded: true,
            lastModified: new Date()
          }
        });
      })
      .catch(() => {
        this.setState({
          ...this.state,
          data: {
            loaded: false,
            lastModified: new Date()
          }
        });
      })
  }

  loadAdminConfigurations() {
    let url = this.getBaseUrl() + '/ext/cc-ui/rest/api/v1/properties_fields/ui-admin?acceptFormat=json';
    Axios.get(url)
      .then((result) => {
        let res = this.filterConfigurations(result.data.propertiesWithFields);
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            configs: {
              ...this.state.data.configs,
              ...res.configs
            },
            i18n: {
              ...this.state.data.i18n,
              ...res.i18n
            },
            alli18n: [
              ...this.state.data.alli18n,
              ...res.alli18n
            ],
            loadedAdmin: true,
            lastModified: new Date()
          }
        });
        //this.setState({ configs : { ...this.state.configs, configs}, loadedAdmin: true, lastModified: new Date() });
      })
      .catch(() => {
        this.setState({
          ...this.state,
          data: {
            loadedAdmin: false,
            lastModified: new Date()
          }
        });
      })
  }

  filterConfigurations(propertiesWithFields) {
    let configs = {};
    let i18n = {};
    let alli18n =[]
    propertiesWithFields.map((property) => {
      if(property['properties']['filename'].startsWith('retrievo-ui-messages-')) {
        let locale = property['properties']['filename'].replace('retrievo-ui-messages-', '').replace('.properties', '');
        let localeWithoutRegionCode = this.getLocaleWithoutRegionCode(locale);
        alli18n.push(localeWithoutRegionCode)
        i18n[locale] = {};
        i18n[localeWithoutRegionCode] = {};
        //console.log("Loading i18n file: " + property['properties']['filename']);
        property['fields'].map((field) => {
          i18n[locale][field.key] = field.value;
          i18n[localeWithoutRegionCode][field.key] = field.value;
        });
      } else {
        //console.log("Loading configuration file: " + property['properties']['filename']);
        property['fields'].map((field) => {
          configs[field.key] = field.value;
        });
      }
    });
    return { configs, i18n, alli18n };
  }

  getLocaleWithoutRegionCode(locale) {
    return locale.split(/[_-]+/)[0];
  }

  getBaseUrl() {
    // toliveira 20190903: workaround for dev mode
    return window.location.href.includes("3000")? 'http://localhost' : '';
  }

  getApiUrl() {
    return this.getBaseUrl() + '/api/v1';
  }

  getProperty(key, defaultValue) {
    let value = this.state.data.configs[key];
    return value !== undefined? value : defaultValue;
  }


  render() {
    return (
      <ConfigStoreProvider value={this.state}>
        {this.props.children}
      </ConfigStoreProvider>
    );
  }
}

// Providing context as higher-order-component
// https://reactjs.org/docs/context.html#consuming-context-with-a-hoc
function withConfigStore(Component) {
  return function configStore(props) {
    return(
      <ConfigStoreConsumer>
        { (configStore) => <Component {...props} configStore={configStore} /> }
      </ConfigStoreConsumer>
    )
  }
}

export { ConfigStore, withConfigStore }
